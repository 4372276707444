import { Errors } from '@inertiajs/core';
import { useEffect, useRef } from 'react';
import { AlertTriangle } from 'react-feather';
import { DeepPartial, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isInViewport } from '../helpers';
import { FadeIn } from './FadeIn';
import { Icon } from './Icon';

export default function ServerErrors<T extends FieldValues = FieldValues>({
  errors,
  defaultValues,
  scrollIntoView = false,
}: {
  errors: Errors;
  /** If present, only errors that belong to keys that are NOT present in the default values, will be shown */
  defaultValues?: Readonly<DeepPartial<T>>,
  scrollIntoView?: boolean,
}) {
  const { t } = useTranslation();
  const messages: string[] = [];

  Object.keys(errors).forEach((key) => {
    // Only show errors that do not belong to any of the default values.
    if (!defaultValues || !(key in defaultValues)) {
      messages.push(errors[key]);
    }
  });

  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollIntoView) {
      if (elementRef.current && !isInViewport(elementRef.current)) {
        const { top } = elementRef.current.getBoundingClientRect();
        window.scrollTo({ top: top + window.scrollY - 100 });
      }
    }
  }, [errors, scrollIntoView]);

  return (
    <FadeIn ref={elementRef} className="flex px-3 py-2 space-x-2 text-red-700 bg-red-100 rounded" role="alert">
      <div>
        <Icon>
          <AlertTriangle />
        </Icon>
      </div>
      <div>
        <h5 className="font-medium">
          {t('shared:validation.something_went_wrong')}
        </h5>
        {messages.length > 0 && (
          <ul>
            {messages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        )}
      </div>
    </FadeIn>
  );
}
