import classNames from 'classnames';
import { FieldError, Merge } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FadeIn } from './FadeIn';
import style from './InputGroup.module.css';

export default function ErrorMessage({
  attribute,
  error,
  message,
}: {
  attribute?: string | null;
  error?: FieldError | Merge<FieldError, (FieldError | undefined[])> | undefined;
  message?: string | null;
}) {
  const { t } = useTranslation();

  if (!error && !message) {
    return null;
  }

  const errorMessage = message || error?.message || (error?.type && t(`shared:validation.${error.type}`, { attribute }));

  return (
    <FadeIn
      className={classNames(style['error-message'], 'text-red-600 text-sm')}
      role="alert"
    >
      {errorMessage}
    </FadeIn>
  );
}
