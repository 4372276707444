import { useEffect } from 'react';

import { darkenColor, getAccessibleColor, hexToRgb, lightenColor } from './helpers';

export const defaultColor = '#00a050';

export function useCharityStyle(charity: { brand_color: string | null; } | null = { brand_color: '#9196a1' }) {
  /** Custom brand color for this project. */
  const color = charity?.brand_color || defaultColor;

  /** Slightly darkened version for hover states. */
  const hoverColor = lightenColor(color, 0.15);

  /**
   * The text color that can be used on top of the brand color.
   * Caculated based on the darkened color to obtain a slight bias towards white.
   */
  const complimentaryColor = getAccessibleColor(darkenColor(color, 0.25));
  const dropShadow = complimentaryColor === '#ffffff' ? '0 1px 2px rgba(0, 0, 0, 0.25)' : 'none';

  useEffect(() => {
    document.body.style.setProperty('--color-project', hexToRgb(color));
    document.body.style.setProperty('--color-project-hover', hexToRgb(hoverColor));
    document.body.style.setProperty('--color-project-comp', hexToRgb(complimentaryColor));
    document.body.style.setProperty('--drop-shadow-project-comp', dropShadow);

    return () => {
      document.body.style.removeProperty('--color-project');
      document.body.style.removeProperty('--color-project-color');
      document.body.style.removeProperty('--color-project-comp');
      document.body.style.removeProperty('--drop-shadow-project-comp');
    };
  }, [color, complimentaryColor, dropShadow]);
}
