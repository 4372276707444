import classNames from 'classnames';
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react';

import style from './InputGroup.module.css';

const InputGroup = forwardRef<HTMLDivElement, DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div className={classNames(className, style['input-group'])} {...props} ref={ref} />
    );
  }
);

InputGroup.displayName = 'InputGroup';

export default InputGroup;
