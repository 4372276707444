import { useMemo } from 'react';

/**
 * Memoizes the given value and only returns a new reference
 * when the value has changed according to a deep comparison.
 */
export default function useMemoizedValue<T = unknown>(value: T) {
  const encoded = typeof value !== 'undefined' ? JSON.stringify(value) : undefined;

  return useMemo<T>(() => (typeof encoded !== 'undefined' ? JSON.parse(encoded) : encoded), [encoded]);
}
