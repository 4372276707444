import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

import style from './Icon.module.css';

export function Icon({
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>) {
  const className = classNames(
    style.icon,
    // Base
    'inline-block -my-[0.15em] shrink-0 w-[1em] h-[1em] [&>svg]:w-full [&>svg]:h-full',
    props.className,
  );

  return (
    <span {...props} className={className} />
  );
}
