import { InertiaLinkProps, Link } from '@inertiajs/react';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { AnchorHTMLAttributes, ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from 'react';

export interface ButtonProps {
  type?: 'submit';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'project' | 'inline' | 'danger' | 'custom';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  loading?: boolean;
  disabled?: boolean;
  iconButton?: boolean;
  className?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>(
  ({ children, ...props }, ref) => {
    const buttonProps = getButtonProps(props);

    return (
      <button type={props.type || 'button'} {...buttonProps} ref={ref}>
        {children}
      </button>
    );
  }
);

Button.displayName = 'Button';

export function LinkButton({
  children,
  ...props
}: ButtonProps & Omit<InertiaLinkProps, keyof ButtonProps>) {
  const buttonProps = omit(
    getButtonProps({ ...props, className: classNames(props.className, 'inline-block') }),
    'disabled', 'type'
  );

  return (
    <Link {...buttonProps}>
      {children}
    </Link>
  );
}

export const AButton = forwardRef<HTMLAnchorElement, ButtonProps & DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>>(
  ({ children, ...props }, ref) => {
    const buttonProps = omit(
      getButtonProps({ ...props, className: classNames(props.className, 'inline-block') }),
      'disabled', 'type'
    );

    return (
      <a {...buttonProps} ref={ref}>
        {children}
      </a>
    );
  }
);

AButton.displayName = 'AButton';

export function getButtonProps<P extends object = object>(props?: ButtonProps & P) {
  const hasShadow = props?.variant !== 'tertiary' && props?.variant !== 'inline';
  const hasSpacing = props?.variant !== 'inline';

  const classes = classNames(
    // Base
    'rounded-md focus:outline-none focus:ring focus:ring-offset-1 transition-colors whitespace-nowrap text-center',
    props?.type === 'submit' ? 'font-bold' : 'font-medium',
    // Variants
    (props?.variant === 'primary' || !props?.variant) && 'text-white bg-emerald-600 border border-emerald-600 hover:text-white hover:bg-emerald-500 hover:border-emerald-500 focus:ring-emerald-700/30',
    props?.variant === 'secondary' && 'text-slate-800 bg-slate-50 border border-slate-250 hover:text-slate-900 hover:bg-white focus:ring-slate-300/30',
    (props?.variant === 'tertiary' || props?.variant === 'inline') && 'text-slate-500 hover:text-slate-800 bg-transparent border border-transparent focus:ring-slate-300/30',
    props?.variant === 'project' && 'bg-project border border-project text-project-comp hover:bg-project-hover hover:border-project-hover hover:text-project-comp focus:ring-project/30',
    props?.variant === 'danger' && 'text-red-700 bg-red-200 border border-red-200 hover:text-red-700 hover:bg-red-100 hover:border-red-100 focus:ring-red-100/30',
    hasShadow && 'shadow-[inset_0_-1px_1px_rgba(0,0,0,.075),inset_0_15px_15px_-15px_rgba(255,255,255,.1),inset_0px_1px_1px_rgba(255,255,255,0.05)]',
    // Sizes
    hasSpacing && props?.size === 'xs' && 'h-6 leading-[22px] px-1 text-sm',
    hasSpacing && props?.size === 'sm' && 'h-8 leading-[30px] px-2.5',
    hasSpacing && (props?.size === 'md' || !props?.size) && 'h-10 leading-[38px] px-4',
    hasSpacing && props?.size === 'lg' && 'h-12 leading-[46px] px-8',
    // Loading
    props?.loading && 'animate-pulse',
    props?.disabled && 'opacity-50 pointer-events-none',
    // Icon button
    props?.iconButton && '!px-0 aspect-square',
    // Rest
    props?.className,
  );

  return {
    ...omit(props, 'variant', 'size', 'loading', 'iconButton'),
    disabled: props?.loading || props?.disabled,
    className: classes,
  };
}
